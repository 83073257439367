import React from "react"
import { graphql } from "gatsby"
import SEOArticle from "../components/SEO/article"
import SEOWebpage from "../components/SEO/webpage"
import Head from "../components/Head"
import Layout from "../components/Layout"
import ShareButtons from "../components/ShareButtons"
import ArticleFooter from "../components/ArticleFooter/index"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const LocationPagesTemplate = ({ data, pageContext, location }) => {
  const post = data.graphCmsServiceArea
  const services = data.allGraphCmsServicePosts.edges;

  const { slug } = pageContext
  const postUrl = data.site.siteMetadata.siteUrl + '/service-areas/' + slug + "/";
  const pageTitle = data.graphCmsServiceArea.locationName + ', ' + data.graphCmsServiceArea.stateLocation + ' Refrigeration and HVAC Services | ' + data.site.siteMetadata.title;

  return (
    <Layout>
      <Head
        title={pageTitle}
        pageDesc={post.metaDescription}
        url={postUrl}
      />
      <SEOWebpage
        title={pageTitle}
        url={postUrl}
      />
      <article className="locations-post" >
        <header>
          <h1>{pageTitle}</h1>
          <hr />
        </header>
        <div className="articleBody">
          <h2>Your commercial partner proudly serving {data.graphCmsServiceArea.locationName}, {data.graphCmsServiceArea.stateLocation}</h2>
          <p> Valley Commercial Service is proud to serve the businesses of {data.graphCmsServiceArea.locationName}, {data.graphCmsServiceArea.stateLocation} for all their HVAC, Refrigeration, Freezer and cooking equipment needs! </p>

          <div dangerouslySetInnerHTML={{ __html: post.body.html }}/>

          <h2>Services we offer in {data.graphCmsServiceArea.locationName}, {data.graphCmsServiceArea.stateLocation} </h2>
          <ul className="iconBlocks">
            {services.map((service, index) =>
              <li><a href={'/services/' + service.node.slug + '/'}>{service.node.title}</a></li>
            )}
          </ul>
          <ArticleFooter />
          </div>
      </article>
    </Layout>
  )
}

export default LocationPagesTemplate

export const pageQuery = graphql`
query LocationAreas($slug: String!) {
  site {
    siteMetadata {
      siteUrl
      title
    }
  }
  graphCmsServiceArea(slug: {eq: $slug}) {
    id
    slug
    locationName
    stateLocation
    body {
      html
    }
  }
  allGraphCmsServicePosts {
    edges {
      node {
        title
        slug
        metaDescription
      }
    }
  }
}
`
